@function font-files($files...) {
    $result: null;

    @for $i from 2 through length($files) {
        @if $i % 2 == 0 {
            $file: nth($files, $i - 1);
            $format: nth($files, $i);
            $result: append($result, unquote("url(#{$file}) format('#{$format}')"), 'comma');
        }
    }

    @return $result;
}

@function font-url($url) {
    @return unquote("url(#{unquote($url)})");
}

