.btn {
    background-color: $brandBlue;
    @include filter-gradient($brandBlue, $detroitBlue);
    @include background(linear-gradient($brandBlue, $detroitBlue));
    border: 1px solid $brandBlue;
    @include border-radius(2px);
    color: $white !important;
    text-shadow: none;
    height: 2.5rem;
    padding: 0;
    margin: 5px 0;
    min-width: 13.1em;
    font-family: $sansFontFamilyRegular;
    @include font-size(18);
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -webkit-box-align: center;

    &:hover:enabled, &:focus:enabled {
        background-color: $detroitBlue;
        color: $white;
        border: 1px solid $detroitBlue;
        text-decoration: underline;
        @include filter-gradient($detroitBlue, $darkBlue);
        @include background(linear-gradient($detroitBlue, $darkBlue));
    }

    &:active:enabled {
        background-color: $darkBlue;
        @include background(linear-gradient($darkBlue, $darkBlue));
        @include filter-gradient($darkBlue, $darkBlue);
    }

    + .btn {
        margin-left: 18px;
    }

    & [class*="icon-"]:before {
        margin-left: 7px;
        @include font-size(14);
    }

    [class*="span"] > & {
        min-width: 100%;
    }
}

.btn-secondary {
    @include filter-gradient($mediumGray, $cadetGray);
    @include background(linear-gradient($mediumGray, $cadetGray));
    border: 1px solid $mediumGray;

    &:hover:enabled, &:focus:enabled {
        @include filter-gradient($cadetGray, $darkCadetGray);
        @include background(linear-gradient($cadetGray, $darkCadetGray));
        border: 1px solid $cadetGray;
    }

    &:active:enabled {
        background-color: $darkCadetGray;
        border: 1px solid $darkCadetGray;
    }
}

.btn-ghost {
    background: $white;
    min-width: 8.19em !important;
    height: 35px;
    border: 1px solid $detroitBlue;
    color: $detroitBlue !important;
    @include font-size(16);

    &:hover:enabled {
        background: $white;
        border: 1px solid $brandBlue;
    }

    &:focus:enabled {
        background: $white;
        text-decoration: none;
        border: 2px solid $brandBlue;
    }

    &:active:enabled {
        text-decoration: underline;
        background: $white;
    }
}

a.btn {
    line-height: 40px;
    display: inline-block;
    @extend .btn:enabled;

    &:focus {
        outline-width: 1px;
    }
}

a.btn-secondary {
    @extend .btn-secondary:enabled;
}

.btn-search {
    background: transparent;
    border: 0;
    bottom: 0;
    color: $linkColor;
    display: inline;
    padding: 0;
    right: 4px;
    width: auto;
    -webkit-appearance: none;
    @include box-shadow(none);

    &:hover {
        color: $lightBlue;
    }
}

@-moz-document url-prefix() {
    .btn-search {
        bottom: 2px;
    }
}

.btn-fullWidth {
    width: 100%;
    min-width: 0;
}

.btn-link {
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    color: $linkColor;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: $standardFontFamily;
    line-height: $baseLineHeight;
    @include font-size($baseFontSize);

    &:hover {
        color: $linkHoverColor;
    }

    &:focus {
        outline: 1px dotted $gray01;
    }
}

.row-buttons {
    text-align: right;
    margin: 0;
    @include margin("top", "base");

    .btn {
        float: right;
        margin: 0;

        + .btn {
            margin-right: 22px;
        }
    }
}

