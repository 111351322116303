@import "compass-shim";

@include font-face("AmericanSans", font-files(
    "#{$americanFontPath}americansans-regular.woff", woff,
    "#{$americanFontPath}americansans-regular.ttf", truetype,
    "#{$americanFontPath}americansans-regular.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-regular.eot", normal, normal);

@include font-face("AmericanSans", font-files(
    "#{$americanFontPath}americansans-regularitalic.woff", woff,
    "#{$americanFontPath}americansans-regularitalic.ttf", truetype,
    "#{$americanFontPath}americansans-regularitalic.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-regularitalic.eot", normal, italic);

@include font-face("AmericanSans", font-files(
    "#{$americanFontPath}americansans-bold.woff", woff,
    "#{$americanFontPath}americansans-bold.ttf", truetype,
    "#{$americanFontPath}americansans-bold.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-bold.eot", bold, normal);

@include font-face("AmericanSans", font-files(
    "#{$americanFontPath}americansans-bold_italic.woff", woff,
    "#{$americanFontPath}americansans-bold_italic.ttf", truetype,
    "#{$americanFontPath}americansans-bold_italic.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-bold_italic.eot", bold, italic);

@include font-face("AmericanSansLight", font-files(
    "#{$americanFontPath}americansans-light.woff", woff,
    "#{$americanFontPath}americansans-light.ttf", truetype,
    "#{$americanFontPath}americansans-light.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-light.eot", normal, normal);

@include font-face("AmericanSansLight", font-files(
    "#{$americanFontPath}americansans-light_italic.woff", woff,
    "#{$americanFontPath}americansans-light_italic.ttf", truetype,
    "#{$americanFontPath}americansans-light_italic.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-light_italic.eot", normal, italic);

@include font-face("AmericanSansMedium", font-files(
    "#{$americanFontPath}americansans-medium.woff", woff,
    "#{$americanFontPath}americansans-medium.ttf", truetype,
    "#{$americanFontPath}americansans-medium.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-medium.eot", normal, normal);

@include font-face("AmericanSansMedium", font-files(
    "#{$americanFontPath}americansans-medium_italic.woff", woff,
    "#{$americanFontPath}americansans-medium_italic.ttf", truetype,
    "#{$americanFontPath}americansans-medium_italic.svg#FontAwesomeRegular", svg),
    "#{$americanFontPath}americansans-medium_italic.eot", normal, italic);
