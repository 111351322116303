/*! jQuery UI - v1.10.4 - 2015-02-17
* Base CSS Overriding styles for AA.com
* Copyright 2015 aa.com */

// Layout helpers
.ui-front {
    z-index: 1000;
}

// Component containers
.ui-widget {
    font-family: $baseFontFamily;
    font-size: 1em;
}


.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: $baseFontFamily;
    font-size: 1em;
}

.ui-widget input[type="submit"],
.ui-widget button {
    @include font-size(18);
    font-family: $sansFontFamilyRegular;
    line-height: 1em;
}

.ui-widget .ui-datepicker-trigger {
    font-family: "American Icons";
    font-size: 18px !important;
    line-height: normal;
}

.ui-widget-content {
    border: none;
    background: #fff;
    color: $gray05;

    a {
        color: $brandBlue;
    }
}

.ui-widget-header {
    border: 0;
    background: $brandBlue;
    color: #fff;
    font-weight: bold;
}

// Interaction states
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    border: 1px solid #ccc;
    background: white;
    color: $linkColor;
    outline: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    border: 1px solid #ccc;
    background: $white;
    font-weight: normal;
    color: $linkHoverColor;
    outline: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    border: 1px solid #ccc;
    background: #17337a  url(/content/images/backgrounds/containers/aa-tableHdrBkg-alt.gif) 0 0 repeat-x;
    font-weight: bold;
    color: #ffffff;
    outline: none;
}

// Interaction Cues
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    background: #fbf9ee/*{bgColorHighlight}*/;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
    background: #fef1ec/*{bgColorError}*/;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    @include opacity(1);
    background-image: none;
    color: $gray;
}

.ui-state-disabled .ui-state-default {
    color: $brandBlue !important;
    @include opacity(0.35);
}

// Icons

/* states and images */
.ui-icon {
    width: 16px;
    height: 16px;
    background-image: url('/content/images/graphics/icons/aa-jqueryUIicons-sprite.png');
}

.ui-widget-content .ui-icon,
.ui-widget-header .ui-icon,
.ui-state-default .ui-icon,
.ui-state-active .ui-icon,
.ui-state-highlight .ui-icon,
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
    background-image: url('/content/images/graphics/icons/aa-jqueryUIicons-sprite.png');
}

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
    background-image: url('/content/images/graphics/icons/aa-jqueryUIicons-hover-sprite.png');
}

// Misc visuals

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
    border-top-left-radius: 0;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
    border-top-right-radius: 0;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
    border-bottom-left-radius: 0;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
    border-bottom-right-radius: 0;
}

/* Overlays */
.ui-widget-overlay {
    background: $black;
    @include opacity(0.66);
}

.ui-widget-shadow {
    background: $gray;
}


// Progress bar
.ui-progressbar .ui-progressbar-overlay {
    background: url(/content/images/graphics/icons/animated-overlay.gif);
}

// Datepicker
.ui-datepicker-trigger {
    background: none;
    border: none;
    padding: 0;
    color: $brandBlue;
    cursor: pointer;
    font-family: "American Icons";
    font-size: 18px;
    position: absolute;
    margin: 0;
    top: 32px;
    right: 8px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        content: "\10e03a";
    }

    &:hover {
        color: $lightBlue;
    }

    &[disabled] {
        color: $gray03;
    }

    img {
        display: none;
    }
}
.ui-datepicker {
    padding: 0;
    width: 38em !important;
    @include font-size(14);
    border: 1px solid $ultraLightGray;

    & .ui-datepicker-header{
        background-color: $brandBlue;
        @include background(linear-gradient($brandBlue, $darkBlue));
        color: $white;
        border-bottom: 1px solid $white;
        margin-bottom: -1px;
        z-index: 1;

        & .ui-datepicker-prev,
        & .ui-datepicker-next {
            &.title, & span {
                display: none;
            }
            &::before {
                content: "";
                height: 5px;
                width: 5px;
                display:inline-block;
                border: 2px solid $white;
                border-top-style: none;
                border-right-style: none;
                margin: 10px;
            }
        }
        & .ui-datepicker-next::before {
            -ms-transform: rotate(225deg); /* IE 9 */
            -webkit-transform: rotate(225deg); /* Chrome, Safari, Opera */
            transform: rotate(225deg);
        }
        & .ui-datepicker-prev::before {
            -ms-transform: rotate(45deg); /* IE 9 */
            -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
            transform: rotate(45deg);
        }
        & .ui-datepicker-prev-hover,
        & .ui-datepicker-next-hover {
            background: none;
            border:none;
            top: 2px;
        }
        & .ui-datepicker-prev-hover {
            left: 2px;
        }
        & .ui-datepicker-next-hover {
            right: 2px;
        }
    }
    & .ui-datepicker-calendar{
        & thead{
            border-bottom: 0;
            background: none;

            & th{
                color: $mediumGray;
                font-family:$baseFontFamily;
                font-size: 100%;
            }
        }
        & tbody{
            border-bottom: 0;

            & tr{
                border-bottom: 0;
                padding: 0;
            }
        }
        & a{
            position: relative;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
            &::before{
                content:"";
                position:absolute;
                width:20px;
                height:20px;
                border-radius:50%;
                left: 15%;
                top: 15%;
            }
        }
        & .ui-state-default{
            text-align: center;
            height: 34px;
            border: none;
            outline: none;
            color: $darkBlue;
            padding: .55em .2em;
        }
        & .ui-state-highlight{
            background:$white;
            &::before{
                border: 2px $brandBlue solid;
            }
        }
        & .ui-state-hover{
            background:$ultraLightGray;
            outline: 1px solid $ultraLightGray;
            text-decoration: underline;
        }
        & .ui-state-disabled {
            & span {
                color: $gray !important;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
                opacity: 1;
            }
            & .ui-state-default {
                color: $darkBlue !important;
                text-decoration: line-through;
            }
        }
        & .aa-highlight {
            background-color: $brandBlue;
            @include background(linear-gradient($brandBlue, $darkBlue));
        }
        & .aa-highlight .ui-state-default,
        & .ui-state-active{
            background-color: $brandBlue;
            @include background(linear-gradient($brandBlue, $darkBlue));
            color: $white;
            font-weight: normal;
            border: 1px solid transparent;
            -moz-border-image: -moz-linear-gradient(top, $brandBlue 0%, $darkBlue 100%);
            -webkit-border-image: -webkit-linear-gradient(top, $brandBlue 0%, $darkBlue 100%);
            border-image: linear-gradient(to bottom, $brandBlue 0%, $darkBlue 100%);
            border-image-slice: 1;
            &.ui-state-highlight{
                &::before{
                    border: 2px $white solid;
                }
            }
        }

    }
    & .ui-datepicker-buttonpane {
        border-top: 1px solid $ultraLightGray !important;
        background-color: $ultraLightGray;
        margin: 0;
        padding: 0;
        border: 0;
        display: table;
        width: 100%;
        & button{
            margin: 1px;
            padding: 6px;
            border:none;
            background-color: $ultraLightGray;
            font-family: $baseFontFamily;
            @include font-size(14);
            color: $darkBlue;
            font-weight: normal;

            &.ui-datepicker-current{
                width: 25%;
                text-align: left;
                opacity: 1;
                filter:Alpha(Opacity=100);
            }
            &.ui-datepicker-close{
                width: 74.4%;
                margin-left:0px;
                text-align: right;
            }
        }
    }


}
.ui-datepicker-row-break{
    clear: none;
    border-left: 1px solid $gray;
    width: 0px;
    left: 50%;
    margin-left: 0px !important;
}


// Dialog
.ui-dialog {
    position: relative;
    width: 300px;
    @include padding('all','xlarge');
    @include font-size(14);
    border: 1px solid $ultraLightGray;

}
.ui-dialog .ui-dialog-titlebar {
    filter: none;
    padding: 0;
    @include margin('bottom','medium');
    margin-top: 0;
    position: relative;
    background-color: $white;
}
.ui-dialog .ui-dialog-titlebar .ui-dialog-title {
    @include head2;
    float: left;
    font-weight: normal;
    margin: 0;
    color: $darkBlue;
    white-space: inherit;
    overflow: visible;
    text-overflow: initial;
}
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close {
    background: $white;
    border: none;
    position: absolute;
    right: -22px;
    top: -26px;
    width: 1.5rem;
    margin: 0;
    padding: 0;
    height: 1.5rem;
    -webkit-box-shadow: none;
    color: $brandBlue;

    &:before {
        content: "\10e04c";
        font-family: "American Icons";
        @include font-size(24);
        height: auto;
        left: 0;
        position: absolute;
        top: 0;
        width: auto;
    }
    &:focus,&:hover {
        border-bottom: 2px solid $brandBlue;
        height: 28px;
    }
     .ui-button-icon-primary{
        background: none;
    }
}

.ui-dialog .ui-dialog-content {
    border: 0;
    margin: 0;
    padding: 0;
    overflow: auto;
    zoom: 1;
    background: none;
}
.ui-dialog .ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin: 0 0 0 0;
    padding: 40px 0 0 0;
    border-top: none;
    box-shadow: none;
    position: relative;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: none;
}

.ui-dialog .ui-dialog-buttonpane button {
    float: right;
    width: 236px;
    margin: 0 0 0 0;

    @extend .btn;

    &.btn-secondary{
        @extend .btn-secondary;
    }

    &::-moz-focus-inner {
        border: 0;
    }

    &.last {
        margin-right: 0px;
    }

    span {
        @include font-size(18);
    }

    + button {
            margin-right: 22px;
            margin-left: 0 !important;
    }

}


/* Autocomplete */

.ui-autocomplete {
    padding: 0px;
    border: 1px solid $darkGray;
    background-color: white;
    overflow: hidden;
    z-index: 99999;
    @include box-shadow(0 1px 1px rgba(0, 6, 10, 0.2));
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    width: 375px;
}

.ui-autocomplete .ui-menu-item a.ui-corner-all {
    display: inline-block;
    margin: 0;
    padding: 5px 9px;
    color: $brandBlue;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    width: 100%;
}

.ui-autocomplete .ui-state-hover,
.ui-autocomplete .ui-state-focus {
    border: 1px solid $brandBlue;
    color: #fff !important;
    background-color: $brandBlue !important;
}

.ui-autocomplete .ui-menu-item {
    margin: 0px;
    padding: 0;
    @include font-small;
    overflow: hidden;
    white-space: nowrap;
    color: $brandBlue;
    font-weight: bold;
}

// Survey - This is not in the right place - NOT a Jquery UI element
.ui-survey-font {
    font-size: 12px;
    color: #999;
}

.ui-tabs {

    padding: 0px;

    .ui-tabs-nav {

        padding: 0 $baseLineHeight/2;
        margin: 0 0 $baseLineHeight;
        background-color: #FFFFFF;
        background-image: none;
        border-bottom: 1px solid $gray;


        li {
            margin: 0px;
            border: 0px;



            .ui-tabs-anchor {
               @include font-size(21);
                font-family: $sansFontFamilyRegular;
                color: $brandBlue;
                outline: none;
                text-align:center;
                width:100%;

                &:hover {
                    color: $lightBlue;
                }
            }

            &.ui-tabs-active {
                background-color: $white;
                background-image: none;
                border-top: 1px solid $gray;
                border-left: 1px solid $gray;
                border-right: 1px solid $gray;
                @include border-top-left-radius(3px);
                @include border-top-right-radius(3px);


                .ui-tabs-anchor {
                    color: $darkBlue;
                    font-weight: normal;
                    &:hover{
                        text-decoration:underline;
                        cursor: pointer;
                    }
                    &:focus{
                        text-decoration:underline;
                    }
                }
            }
            &:hover {
                background-color: #FFFFFF;
            }
        }
    }

    &:not(.ui-tabs-small){
        .ui-tabs-nav {
            li{
                &.ui-state-focus{
                    border: 2px solid $detroitBlue;
                    border-bottom:none;
                    .ui-tabs-anchor{
                        text-decoration:underline;
                    }
                }
                &.ui-state-default{
                    width:25%;
                }
            }
        }
    }

    .ui-tabs-panel {
        @include selfclear;
        padding: 0;
    }

    &.ui-tabs-small {
        .ui-tabs-nav {
            padding: 0;
            border: none;
            li {
                .ui-tabs-anchor {
                    background-color: $white;
                    border: 1px solid $brandBlue;
                    color: $brandBlue;
                    @include font-size($baseFontSize);
                    font-weight: normal;
                    line-height: $baseLineHeight;
                    padding: 0.3em 0.5em;
                    text-align: center;
                    width: 100%;
                }
                &:first-child .ui-tabs-anchor {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child .ui-tabs-anchor {
                    border-radius: 0 3px 3px 0;
                }
                &.ui-state-focus .ui-tabs-anchor {
                    text-decoration: underline;
                }
                &.ui-state-hover .ui-tabs-anchor {
                    text-decoration: underline;
                }
                &.ui-state-active,&.ui-tabs-active {
                    border: none;
                    border-bottom: 1px solid white;
                    margin: 0;
                    padding: 0;
                    z-index:1;
                    .ui-tabs-anchor {
                        background-color: $brandBlue;
                        color: $white;
                    }
                }
                + li {
                    margin-left:-1px !important;
                }
            }
        }
    }
}

